<template>
  <div>
    <ds-header title="周期活动列表"></ds-header>
    <a-form layout="inline" style="margin-bottom: 10px">
      <a-form-item label="周期活动专题名称">
        <a-input v-model.trim="searchForm.activityTitle" placeholder="请输入"></a-input>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px" icon="search"> 查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm" icon="redo">重置</a-button> </span
      ><br />
      <a-button type="primary" @click="addActive" style="margin-top: 3px" icon="plus"> 新建周期活动 </a-button>
    </a-form>
    <div style="margin-top: 10px">
      <a-table
        :rowKey="(record, index) => record.id"
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination.total ? pagination : false"
        @change="pageChange"
        bordered
      >
        <span slot="action" slot-scope="text, record">
          <a-button type="link" @click="editTitle(record)">修改专题名称</a-button>
          <a-button style="margin-left: 5px" type="link" @click="lookActive(record)">查看活动列表</a-button>
        </span>
      </a-table>
    </div>
    <!-- 修改专题名称 -->
    <a-modal v-if="editTitleVisible" title="修改专题名称" :visible="editTitleVisible" @ok="submit" @cancel="editTitleVisible = false">
      <a-form-model ref="ruleForm" :label-col="labelCol" :wrapper-col="wrapperCol" :model="formData" :rules="rules">
        <a-form-model-item label="周期活动专题" prop="periodActivityTitle">
          <a-input v-model.trim="formData.periodActivityTitle" placeholder="请输入周期活动专题名称" :max-length="40"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import * as api from '@/api/enterpriseServiceActivitiesManagement/index.js';
export default {
  data() {
    return {
      searchForm: {},
      formData: {},
      tableData: [],
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      activityId: '', //选中的活动id
      columns: [
        {
          title: '周期活动专题',
          dataIndex: 'periodActivityTitle',
          key: 'periodActivityTitle',
        },
        {
          title: '创建时间',
          dataIndex: 'createDate',
          key: 'createDate',
        },
        {
          title: '最后操作时间',
          dataIndex: 'updateDate',
          key: 'updateDate',
        },
        {
          title: '最后操作人',
          dataIndex: 'lastOperator',
          key: 'lastOperator',
        },
        {
          title: '操作',
          key: 'action',
          width: 300,
          scopedSlots: { customRender: 'action' },
        },
      ],
      rules: {
        periodActivityTitle: [{ required: true, message: '请输入周期活动专题', trigger: 'blur' }],
      },
      editTitleVisible: false,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let obj = {
        periodicActivityTitle: this.searchForm.activityTitle,
        page: this.pagination.current - 1,
        size: this.pagination.pageSize,
      };
      api.getPeriodicActivityPageList(obj).then((res) => {
        if (res.code == '200') {
          this.tableData = res.data.data;
          this.pagination.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //提交修改专题名称
    async submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let obj = {
            title: this.formData.periodActivityTitle,
            activityId: this.formData.periodActivityId,
          };
          api.updatePeriodActivityTitle(obj).then((res) => {
            if (res.code === '200') {
              this.$message.success('修改成功');
              this.editTitleVisible = false;
              this.getData();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    addActive() {
      this.$router.push({
        path: '/cycleActivities/add',
        query: {},
      });
    },
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getData();
    },
    //修改专题名称
    editTitle(data) {
      this.formData = JSON.parse(JSON.stringify(data));
      this.editTitleVisible = true;
    },
    //查看活动列表
    lookActive(record) {
      this.$router.push({
        path: '/cycleActivities/cycleList',
        query: {
          periodActivityId: record.periodActivityId,
          periodActivityTitle: record.periodActivityTitle,
        },
      });
    },
    resetSearchForm() {
      this.searchForm = {
        activityTitle: '', // 活动标题
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData();
    },
    search() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData();
    },
  },
};
</script>

<style></style>
