import http, { fetchApi } from '@/utils/axios';
import URL from '@/api/urlConfig';
const requestUrl = '/api/dscloud-ies-platform';
// 列表
export const GET_LIST_ACTIVITY = (data) => {
  return fetchApi(URL.GET_LIST_ACTIVITY, data, 'post', 'data');
};
// 详情
export const GET_LIST_DETAIL = (data) => {
  return fetchApi(URL.GET_LIST_DETAIL, data, 'post', 'params');
};
// 编辑详情
export const GET_LIST_MOD = (data) => {
  return fetchApi(URL.GET_LIST_MOD, data, 'post', 'params');
};
// 更新活动
export const UPDATE_CALENDER_ACTIVITY = (data) => {
  return fetchApi(URL.UPDATE_CALENDER_ACTIVITY, data, 'post', 'data');
};
// 创建活动
export const INSET_ACTIVITY = (data) => {
  console.log(data);
  return fetchApi(URL.INSET_ACTIVITY, data, 'post', 'data');
};
// 发布/取消校验
export const GET_LIST_PUBLISH = (data) => {
  return fetchApi(URL.GET_LIST_PUBLISH, data, 'post', 'params');
};
export const SET_LIST_PUBLISH = (data) => {
  return fetchApi(URL.SET_LIST_PUBLISH, data, 'post', 'data');
};
// 导出报名信息
// export const GET_LIST_REGISTRANT = (data) => {
//   return fetchApi(URL.GET_LIST_REGISTRANT, data, "post", 'params');
// };
export const GET_LIST_REGISTRANT = (data) => {
  return http({
    url: URL.GET_LIST_REGISTRANT,
    responseType: 'blob',
    timeout: 120000,
    method: 'post',
    data,
  });
};
// 下载模板
export const GET_TEMPLATE = (data) => {
  return http({
    url: URL.GET_TEMPLATE,
    responseType: 'blob',
    timeout: 120000,
    method: 'post',
  });
  // return fetchApi(URL.GET_TEMPLATE, data, "post", '');
};
// 下载此活动指定用户信息
export const GET_USER_LIST = (data) => {
  return http({
    url: URL.GET_USER_LIST,
    responseType: 'blob',
    timeout: 120000,
    method: 'post',
    params: data,
  });
  // return fetchApi(URL.GET_USER_LIST, data, "post", 'params');
};
// 获取活动类型
export const GET_LISTA_CTIVITYLIST = (data) => {
  return fetchApi(URL.GET_LISTA_CTIVITYLIST, data, 'post', 'data');
};
/**
 * @description: 获取所属项目
 * @return {*}
 */
export function FINDPROJECT() {
  return fetchApi(URL.GET_ALL_PROJECT_LIST, null, 'get');
}
// 获取公司
export const FIND_ENTERPRIES_TYPE = (data) => {
  return fetchApi(URL.FIND_ENTERPRIES_TYPE, data, 'post', 'data');
};
// 获取公司
export const GET_PLATPORM_QUESTIONNAIRE = (data) => {
  return fetchApi(URL.GET_PLATPORM_QUESTIONNAIRE, data, 'post', 'data');
};
// 上传文件
export const importUserFile = (data) => {
  return fetchApi(URL.importUserFile, data, 'post', 'data');
};
// 获取企业类型
export function getEnterpriseType() {
  return http({
    url: '/api/enterprise/enterprise/types/list',
    method: 'get',
  });
}
// 获取企业类型列表
export const getTypeList = (data) => {
  return fetchApi(URL.getTypeList, data, 'post', 'data');
};
// 添加企业类型列表
export const addTypeList = (data) => {
  return fetchApi(URL.addTypeList, data, 'post', 'data');
};
// 修改企业类型列表
export const updateTypeList = (data) => {
  return fetchApi(URL.updateTypeList, data, 'post', 'data');
};
// 获取企业类型项目列表
export const getProjectDownload = (data) => {
  return fetchApi(URL.getProjectDownload, '', 'get');
};
// 删除企业类型项目列表
export const delTypeList = (data) => {
  return fetchApi(URL.delTypeList + data, '', 'delete');
};
// 获取企业类型详情
export const getTypeInfo = (data) => {
  return fetchApi(URL.getTypeInfo + data, '', 'get');
};
// 获取企业类型详情
export const updataJietu = (url, data) => {
  return fetchApi(url, data, 'post', 'data');
};
// 查询场地日期
export const getplaceDateList = (data) => {
  return fetchApi('/api/place/maintain/selectRuleDate', data, 'post', 'data');
};
// 根据项目查询场地
export const getplacList = (data) => {
  return fetchApi('/api/place/facility/findFacilityByProjectId/' + data, null, 'get');
};
// 查询场地时间
export const getsiteTimeList = (data) => {
  return fetchApi('/api/place/maintain/list', data, 'GET');
};
// 导出线上签到记录
export const exportSignInBut = (data) => {
  return http({
    url: '/api/dscloud-ies-platform/activity/online/exportOnline',
    responseType: 'blob',
    timeout: 120000,
    method: 'post',
    data,
  });
};
// 查询企服日历
export const getActivityPeriod = (data) => {
  return fetchApi(requestUrl + '/activity/period/selActivityPeriod', data, 'post', 'data');
};
// 周期活动分页列表
export const getPeriodicActivityPageList = (data) => {
  return fetchApi(requestUrl + '/web/periodicActivity/getPeriodicActivityPageList', data, 'post', 'data');
};
// 周期活动修改专题名称
export const updatePeriodActivityTitle = (data) => {
  return fetchApi(requestUrl + '/web/periodicActivity/updatePeriodActivityTitle', data, 'post', 'data');
};
// 周期活动获取活动列表
export const getActivityList = (data) => {
  return fetchApi(requestUrl + '/web/periodicActivity/getActivityList', data, 'post', 'data');
};
// 新增周期活动
export const addPeriodicActivity = (data) => {
  return fetchApi(requestUrl + '/web/periodicActivity/addPeriodicActivity', data, 'post', 'data');
};
// 生成周期活动预览
export const buildPeriodicActivityPreview = (data) => {
  return fetchApi(requestUrl + '/web/periodicActivity/buildPeriodicActivityPreview', data, 'post', 'data');
};
// 周期活动批量发布功能
export const batchPublishPeriodicActivity = (data) => {
  return fetchApi(requestUrl + '/web/periodicActivity/batchPublishPeriodicActivity', data, 'post', 'data');
};
// 根据场地id获取场次时间段信息
export const getFacilitySessionTime = (data) => {
  return fetchApi('/api/place/maintain/getFacilitySessionTime', data, 'post', 'data');
};
